<template>
  <div class='settlement-list-container' :style='`height:calc(${style.height}px - 130px);`'>
    <router-view v-show='this.$route.matched.length==3'></router-view>
    <PageHeaderLayout v-show='this.$route.matched.length==2' class='layout'>
      <div class='main-page-content'>
        <!-- 搜索-->
        <el-form v-model='formQuery' :inline='true' label-position='right' class='table-search'>
          <div>
            <el-form-item>
              <el-input v-model='formQuery.proj_name' @change='handleQuery' clearable placeholder='请输入项目名称' />
            </el-form-item>
            <el-form-item>
              <el-select v-model='formQuery.brand' filterable default-first-option remote clearable
                         :remote-method="(_)=>{handelRemoteSelect(_,'brand')}" placeholder='请选择品牌'>
                <el-option v-for='item in options.brand' :key='item' :label='item' :value='item'
                           @change='handleQuery' />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input @change='handleQuery' clearable placeholder='产品/内容' v-model='formQuery.product' />
            </el-form-item>
            <el-form-item>
              <el-select v-model='formQuery.kol_nickname' filterable default-first-option remote clearable
                         :remote-method="(_)=>{handelRemoteSelect(_,'kol_nickname')}" placeholder='请选择红人昵称'
                         @change='handleQuery'>
                <el-option v-for='item in options.kol_nickname' :key='item' :label='item' :value='item' />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select v-model='formQuery.name' filterable default-first-option remote clearable
                         :remote-method="(_)=>{handelRemoteSelect(_,'name')}" placeholder='请选择真实姓名'
                         @change='handleQuery'>
                <el-option v-for='item in options.name' :key='item' :label='item' :value='item' />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button @click='handleQuery' type='primary' icon='el-icon-search'>查询</el-button>
            </el-form-item>
            <el-form-item>

            <el-button v-if="userPermissions.indexOf('settle_proj_create') > -1"
                       size='mini' type='primary' @click='handelAdd' icon='el-icon-plus'>
              新增项目
            </el-button>
            </el-form-item>
          </div>
          <div>
          </div>
          <div>
            <el-form-item>
              <el-button @click='handelExport' type='primary' icon='el-icon-download'>批量导出</el-button>
            </el-form-item>
          </div>
        </el-form>
        <div v-if="userPermissions.indexOf('settle_proj_list') !== -1" class='default-table'>
          <el-table ref='table' :data='dataList' v-loading='loadingStatus' border stripe
                    :height='`calc(${style.height}px - 282px)`'>
            <el-table-column type='selection' width='50' />
            <el-table-column align='center' label='序号' type='index' width='55' />
            <el-table-column align='center' label='项目发布日期' min-width='100'>
              <template slot-scope='{row}'>
                {{ $utils.parseTime(row.publish_time, '{y}/{m}/{d}') }}
              </template>
            </el-table-column>
            <el-table-column align='center' label='项目名称' min-width='120' show-overflow-tooltip>
              <template slot-scope='{row}'>
                <el-button v-if="userPermissions.indexOf('settle_proj_detail') > -1"
                           type='text' @click='handelToDetail(row)'>{{ row.proj_name }}
                </el-button>
                <span  v-else> {{ row.proj_name }}</span>
              </template>
            </el-table-column>
            <el-table-column align='center' label='品牌' min-width='100' prop='brand' show-overflow-tooltip />
            <el-table-column align='center' label='产品/内容' min-width='100' prop='product' show-overflow-tooltip />
            <el-table-column align='right' label='红人数' width='100' prop='kol_count' />
            <el-table-column align='right' label='项目总金额' prop='total_amount' width='100'>
              <template slot-scope='{row}'>{{ $utils.numberFormat(row.total_amount, 2) }}</template>
            </el-table-column>
            <el-table-column align='right' label='应付款总金额' width='110'>
              <template slot-scope='{row}'>{{ $utils.numberFormat(row.sum_total_amount, 2) }}</template>
            </el-table-column>
            <el-table-column align='center' label='信息收集进度' width='105'>
              <template slot-scope='{row}'>
                {{ calcPercent(row.register_count, row.kol_count) }}
              </template>
            </el-table-column>
            <el-table-column align='center' label='发布进度' prop='publish_process' width='100'>
              <template slot-scope='{row}'>
                {{ calcPercent(row.publish_count, row.kol_count) }}
              </template>
            </el-table-column>
            <el-table-column align='center' label='打款进度' prop='payment_process' width='100'>
              <template slot-scope='{row}'>
                {{ calcPercent(row.pay_count, row.kol_count) }}
              </template>
            </el-table-column>
            <el-table-column align='center' label='发布人' prop='publisher_name' show-overflow-tooltip width='100' />
            <el-table-column align='center' fixed='right' label='操作' width='120'>
              <template slot-scope='{row}'>
                <el-button type='text' @click='handleEdit(row)' icon='el-icon-edit' style='margin-right: 10px;'
                           v-if="userPermissions.indexOf('settle_proj_update') > -1">修改
                </el-button>
                <el-popconfirm :title='`确定删除【${row.proj_name}】的记录吗？`' @confirm='handleDelete(row.id)'>
                  <template #reference>
                    <el-button type='text' icon='el-icon-delete'
                               v-if="userPermissions.indexOf('settle_proj_delete') > -1">删除
                    </el-button>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class='foot-handel'>

          <pagination :limit.sync='formQuery.page_size' :page.sync='formQuery.current_page' :total='formQuery.total'
                      @pagination='getList' />
        </div>
      </div>
    </PageHeaderLayout>
    <el-dialog :visible.sync='dialogStore' :title="`项目结款-${formStore.custom_type==='ADD'?'新增':'修改'}项目`"
               width='550px' center>
      <el-form ref='formStore' :model='formStore' :rules='rules' label-poionsit='right' label-width='110px'>
        <el-form-item label='项目名称' prop='proj_name'>
          <el-input v-model='formStore.proj_name' placeholder='请输入项目名称' />
        </el-form-item>
        <el-form-item label='品牌' prop='brand'>
          <el-input v-model='formStore.brand' placeholder='请输入品牌' />
        </el-form-item>
        <el-form-item label='产品/内容' prop='product'>
          <el-input v-model='formStore.product' placeholder='请输入产品/内容' />
        </el-form-item>
        <el-form-item label='项目总金额' prop='total_amount'>
          <el-input :value='formStore.total_amount' @input="changeValueOnlyNumber($event, 'total_amount')"
                    placeholder='请输入项目总金额' />
        </el-form-item>
        <el-form-item label='项目发布日期' prop='publish_time'>
          <el-date-picker v-model='formStore.publish_time' type='date' value-format='yyyy-MM-dd'
                          placeholder='请选择项目发布日期' style='width: 100%' />
        </el-form-item>
      </el-form>
      <span slot='footer' class='dialog-footer'>
        <el-button @click='dialogStore = false'>取 消</el-button>
        <el-button type='primary' @click='handelStore'>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageHeaderLayout from '@/layouts/PageHeaderLayout.vue'
import FileSaver from 'file-saver'

const rules = {
  proj_name: [
    { required: true, message: '请输入项目名称', trigger: 'blur' },
    { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
  ],
  brand: [{ required: true, message: '请填写品牌', trigger: 'blur' }],
  product: [{ required: true, message: '请填写产品/内容', trigger: 'blur' }],
  total_amount: [{ required: true, message: '请填写项目总金额', trigger: 'blur' }],
  publish_time: [{ required: true, message: '请选择项目发布日期', trigger: 'change' }]
}
/**
 * 保存表单模型
 * @type {{product: null, custom_type: string, total_amount: null, publish_time: null, proj_name: null, brand: null}}
 */
const ModelFormStore = {
  custom_type: 'ADD',
  id: null,
  proj_name: null,
  brand: null,
  product: null,
  total_amount: null,
  publish_time: null
}
export default {
  name: 'Project',
  components: { PageHeaderLayout },
  computed: { ...mapGetters(['userPermissions']) },
  data() {
    return {
      style: {
        height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      },
      rules,
      dialogStore: false,
      loadingStatus: false,
      dataList: [],
      formStore: { ...{}, ...ModelFormStore },
      options: {
        brand: [],
        kol_nickname: [],
        name: []
      },
      formQuery: {
        brand: null,
        kol_nickname: null,
        name: null,
        page_size: 15,
        current_page: 1,
        total: 0
      }
    }
  },
  watch: {
    dialogStore(_) {
      if (_) {
        this.$nextTick(() => {
          this.$refs.formStore.clearValidate()
        })
      }
    }
  },
  mounted() {
    this.getList()
    window.onresize = () => {
      this.clientResize()
    }
  },
  methods: {
    clientResize() {
      this.style.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    },
    calcPercent(dividend, divisor) {
      if (!dividend || !divisor) {
        return '-'
      }
      return (dividend * 100 / divisor).toFixed(2) + '%'
    },
    handelAdd() {
      this.formStore = { ...{}, ...ModelFormStore }
      this.dialogStore = true
    },
    handleEdit(row) {
      this.formStore = { ...{}, ...row }
      this.formStore.custom_type = 'EDIT'
      this.dialogStore = true
    },
    handelStore() {
      this.$refs.formStore.validate(async (valid) => {
        if (valid) {
          const params = this.formStore
          await this.$api.storeSettlement(params, params.custom_type === 'ADD' ? 'create' : 'update')
          this.dialogStore = false
          this.$message.success('操作成功！')
          this.handleQuery()
        }
      })
    },
    async handleDelete(id) {
      await this.$api.delSettlement({ id })
      this.$message.success('操作成功！')
      await this.getList()
    },
    handelToDetail(row) {
      this.$router.push(this.$route.path + '/' + row.id + '/detail')
    },
    async handelRemoteSelect(_, type) {
      const response = await this.$api.optionsSettleSearch({ [type]: _ })
      this.options[type] = response[type] || []
    },
    handleQuery() {
      this.formQuery.current_page = 1
      this.getList()
    },
    async getList() {
      this.loadingStatus = true
      const params = {}
      for (const key in this.formQuery) {
        if (this.formQuery[key]) {
          params[key] = this.formQuery[key]
        }
      }
      delete params.total

      const { list, page_info } = await this.$api.getSettlementList(params)
      this.dataList = list
      this.formQuery.total = page_info.total || 0
      this.loadingStatus = false
    },
    handelExport() {
      const selection = this.$refs.table.selection
      if (!selection.length) {
        this.$message.warning('请选择数据！')
        return false
      }

      const h = this.$createElement
      const dom = []
      const ids = selection.map((_, i) => {
        dom.push(h('span', { class: { artist: true } }, (i + 1) + '. ' + _.proj_name))
        return _.id
      })
      const domMessage = h('div', { style: { maxHeight: '200px', overflowY: 'auto' } }, dom)
      this.$confirm(
        '提示',
        {
          title: '确定导出以下项目吗？',
          message: domMessage,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(async () => {
        await this.handelDownload({ project_ids: ids })
      })
    },
    async handelDownload(param) {
      try {
        const isFileSaverSupported = !!new Blob
        if (isFileSaverSupported) {
          let response = await this.$api.downLoadFile(param, '/settle_proj_detail/export')
          let data = response.data
          let contentType = response.headers['content-type']
          let blob = new Blob([data], { type: contentType })
          FileSaver.saveAs(blob, decodeURI('进度表.xlsx'), { autoBom: true })
        } else {
          this.$message.warning('当前浏览器不支持导出文件')
        }
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    },
    changeValueOnlyNumber(val, virtue) {
      const _val = val.split(',').join('')
      // eslint-disable-next-line no-empty
      if (isNaN(Number(_val))) {
      } else {
        const _indexOf = _val.indexOf('.')
        if (_indexOf >= 0) {
          if (_val.length - 1 - _indexOf <= 2) {
            this.formStore[virtue] = _val
          }
        } else {
          this.formStore[virtue] = _val
        }
      }
    }
  }
}
</script>

<style lang='scss'>
.settlement-list-container {
  .layout {
    height: 100%;
  }

  .page-content {
    min-height: unset;
    height: 100%;

    .main-page-content {
      min-height: unset;
      height: calc(100% - 40px);
    }
  }

  .table-search {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  .foot-handel {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .table-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .el-link--inner {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      span:last-child {
        margin-left: 2px;
      }
    }
  }
}
</style>

<style lang='scss' scoped>
.artist {
  border: 1px solid #f75087;
  color: #f75087;
  border-radius: 19px;
  padding: 0 6px;
  margin: 2px;
  line-height: 1.6;
  float: left;
}
</style>
